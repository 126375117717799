.nav-farmageo-item,
.nav-farmageo-back {
  color: #ffffff !important;
}

.nav-farmageo-item:hover,
.nav-farmageo-back:hover {
  color: #ffffff !important;
  font-weight: 600;
}

.nav-farmageo-back {
  margin-left: 3%;
  font-weight: 500;
}

.nav-item {
  margin-right: 1em;
}

.logo-farmageo {
  width: 230px;
  margin-left: 12%;
}

.buscador-background {
  background-image: url("../assets/images/buscador.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 40px;
  width: 480px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
}

#icono-lupa {
  width: 20px;
  margin-left: 25px;
  background-image: url("../assets/images/Lupa.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.input-search {
  width: 295px;
  border: none;
  outline-style: none;
  margin-top: 8px;
  background-color: #00000000;
}

.select-search {
  border: none;

  outline-style: none;
  display: flex;
  justify-self: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.carrito {
  color: #000000;
  border: none;
  outline-style: none;
  background-color: transparent;
}

#icon-carrito {
  width: 20px;
}

#dropdown {
  padding: 10px;
}

.search-select {
  width: 40px;
}

.search-select-icons {
  width: 20px;
  margin-left: 20px;
}

.nav-central {
  border-bottom: solid 2px #d3d3d3;
}

.nav-inferior {
  background-color: #e8f0fe;
}

.categoria-select {
  outline-style: none;
  border: none;
  font-size: 17px;
  font-weight: 600;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 50%;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
  width: 99%;
}

.categoria-icons-select {
  border-bottom: solid 1px;
}

.nav-inferior-link {
  outline-style: none;
  color: #000000;
  background-color: transparent;
  border: none;
}

.nav-inferior-link:hover {
  outline-style: none;
  color: #000000;
  font-weight: 600;
}

.header-farmacia {
  background-color: #757e8145;
  padding: 3% 10%;
}

#icon-alarma {
  width: 50px;
}

#icon-star {
  width: 70px;
}

.header-farmacia h1 {
  font-weight: 900;
  font-size: 65px;
  margin-top: 20px;
}

.estado-farmacia img,
h3 {
  margin-right: 8px;
  margin-top: 10px;
  vertical-align: baseline;
}

.estado-icon {
  width: 22px;
}

.icon-avatar {
  width: 40px;
  margin-top: 20px;
}

.titular-farmacia {
  font-size: 2em;
  line-height: 1em;
  font-weight: lighter;
}

.link-habla {
  color: #00b300;
  font-size: 1.5em;
  margin-top: 2em;
  font-weight: 700;
  font-style: unset;
}

.nuestros-servicios {
  margin-top: -210px;
}

.icons-lg {
  width: 80px;
}

.icons-md {
  width: 30px;
  margin-bottom: 5px;
}

.bg-verde {
  background-color: #00b300;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}

.nuestros-servicios-icons {
  font-size: 0.6em;
}

#servicios-nav {
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 13px;
}

.bg-claro {
  background-color: #ededed;
}

.icon-info {
  width: 25px;
}

.icon-info-md {
  width: 30px;
}

.centrado {
  padding-left: 10%;
}

.centrado-2 {
  padding-left: 10%;
  padding-right: 10%;
}

.descuento-banner {
  width: 100%;
}

.que-necesitas h2 {
  font-size: 38px;
  font-weight: 800;
}

.que-necesitas h3 {
  padding-top: 20px;
  font-size: 21px;
  font-weight: 800;
}

.que-necesitas {
  line-height: 1em;
}

.que-necesitas #precio {
  font-size: 30px;
}

.icon-oferta {
  width: 40px;
  position: absolute;
  left: -16px;
  top: 5px;
  z-index: 2;
}

.line-left {
  border-left: solid 2px #aaa9a9;
}

#icon-carnet {
  width: 60%;
}

#icon-particular {
  width: 50%;
  margin-top: 15%;
  margin-bottom: 5%;
}

#icon-productos {
  width: 54%;
  margin-top: 15%;
  margin-bottom: 5%;
}

#icon-pami {
  width: 45%;
  margin-top: 15%;
  margin-bottom: 5%;
}

.carousel-indicators {
  bottom: -35px !important;
  position: relative;
}

#slider-home .carousel-indicators {
  /* bottom: 15px !important;*/
  position: absolute;
}

.carousel-indicators li {
  border-radius: 50% !important;
  width: 10px !important;
  height: 10px !important;
  background-color: #b8cfd3 !important;
}

.carousel-indicators .active {
  background-color: #668085 !important;
}

.buscador-central {
  background-color: #b8cfd3;
}

.tabs-productos button {
  color: #000000;
  background-color: transparent;
  border: none;
  border-bottom: solid 2px #e7e0e0;
  width: 100%;
}
.tabs-productos button:focus {
  outline-style: none;
}

.tab-activo {
  outline-style: none;
  border-bottom: solid 3px #000000 !important;
  font-weight: 700;
  margin: 0;
}

.listado-productos {
  line-height: 0.3em;
}

.listado-productos p {
  font-weight: 600;
  font-size: 0.8em;
  margin-top: 1em;
  line-height: 1em;
}

.listado-productos small {
  color: #46a19b;
}

.btn-add-to-car {
  background-color: #51a8c4;
  font-weight: 800;
  color: #ffffff !important;
  font-size: 0.8em !important;
  margin-top: 1em;
  padding: 5px 40px;
}

a.btn.btn-add-to-car:hover {
  background-color: #2b3455;
  color: white;
}

.btn-ver-producto {
  background-color: #af0000;
  color: #ffffff;
  font-size: 0.8em !important;
  margin-top: 1em;
}

.border-prod-2 {
  border-left: dotted 3px #9fa0a0;
}

.border-prod-3 {
  border-left: dotted 3px #9fa0a0;
  border-right: dotted 3px #9fa0a0;
}

.banners {
  line-height: 0.8em;
}

.banners p {
  font-size: 20px;
  font-weight: lighter;
}
.banners h3 {
  font-weight: 800;
  font-size: 20px;
}

.suscribite {
  background-color: #2b3455;
  line-height: 0.3em;
  color: #ffffff;
}

.suscribite-mutual {
  background-color: #af0000;
  line-height: 0.3em;
  color: #ffffff;
}

footer {
  background-color: #f0f2f2;
  margin-left: -15px;
  margin-right: -15px;
}

footer a {
  display: block;
  color: #000000;
}

.mutual {
  background-color: #282828 !important;
  color: #ffffff;
}
.mutual a {
  color: #ffffff;
}

.banner-slider {
  height: 50vh;
  background-color: #aef1bf;
  text-align: center;
  padding-top: 7%;
}

.banner-slider-mutual {
  height: 40vh;
  background-color: #af0000;
  text-align: center;
  padding-top: 7%;
}

#info-proceso img {
  width: 80%;
  float: right;
}

#cercanas-y-de-turno-banner h1 {
  font-weight: 400;
  font-size: 2.9rem;
  margin-top: 0.4em;
  line-height: 1em;
}

.btn-registra-farmacia {
  background-color: #51a8c4;
  color: #ffffff;
}

.listado h1 {
  font-weight: lighter;
  line-height: 1em;
}

.listado h2 {
  font-weight: 700;
  line-height: 0.8em;
}

.icons-listado {
  width: 26px;
}

.btn-ir-a-farmacia {
  background-color: #46a19b;
  color: #ffffff !important;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  font-size: 1.2em;
  font-weight: 600;
  width: 100%;
  border: none;
  padding: 5px 0;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 5%);
}
.sombreado {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 5%);
  border-radius: 13px;
  padding-top: 5px;
}

.pagination {
  justify-content: flex-end;
}

.pagination li {
  margin: 1em;
}

.pagination a {
  color: #000000;
  font-weight: 300;
}

.active {
  font-weight: 900 !important;
}

a.undefined {
  font-weight: 700;
}

.only-desktop {
  display: inline;
}
.descarga-app p {
  font-size: 25px;
  font-weight: lighter;
  margin-top: 20px;
}
.descarga-app h3 {
  font-weight: 800;
  font-size: 30px;
}
.descarga-app small {
  font-size: 15px;
  display: block;
}
div.descarga-app {
  /*height: 30vh;*/
  background-color: #c6ddf7;
}
.registra-tu-farmacia {
  padding: 50px !important;
}

@media (max-width: 500px) {
  .buscador-background {
    height: 40px;
    width: 92vw;
  }
  .input-search {
    width: 60vw;
    margin-top: 2px;
    font-size: 0.8em;
  }
  .dropdown.d-inline {
    /* width: 20vw; */
    position: absolute;
    left: 3vw;
  }
  .d-inline.search-input {
    position: absolute;
    left: 21vw;
  }
  .d-inline.search-lupa {
    position: absolute;
    right: 6vw;
  }

  .nav-farmageo-back {
    display: none;
  }
  .only-desktop {
    display: none;
  }
  .nuestros-servicios {
    margin-top: 0px;
  }
  .nav-inferior {
    /*padding-right: 0px;*/
    padding: 0 !important;
  }
  .nav-central {
    border: none;
  }
  div.descarga-app {
    height: auto;
    text-align: center !important;
  }
  .descarga-app img {
    position: relative !important;
  }
  #logo-colegio {
    width: 100%;
  }
  .registra-tu-farmacia {
    padding: 0px !important;
    text-align: center;
  }
  .registra-tu-farmacia h3 {
    font-size: 23px !important;
  }

  .tabs-productos button {
    font-size: 15px;
    margin-top: 18px;
  }
  .centrado-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

#logo-mobile {
  display: none;
}

.hide-desktop {
  display: none;
}
.btn-carrito-mob {
  display: none;
}

@media (max-width: 700px) {
  .hide-mobile {
    display: none;
  }
  .hide-desktop {
    display: block;
  }
  #info-proceso img {
    width: 30% !important;
    float: none;
  }
  #info-proceso .row {
    text-align: center !important;
    font-size: 10px;
  }
  #info-proceso {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  #cercanas-y-de-turno-banner h1 {
    font-weight: 400;
    font-size: 1.4rem;
    margin-top: 0.4em;
    line-height: 1em;
  }
  #cercanas-y-de-turno-banner p {
    font-size: 0.7rem;
  }
  #cercanas-y-de-turno-banner {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    margin-top: 5px !important;
  }
  .logo-colegio {
    text-align: center !important;
  }
  .suscribite {
    font-size: 9px !important;
    line-height: 1;
  }
  .suscribite p {
    margin: 0;
  }
  .suscribite .redes {
    text-align: right;
  }
  .suscribite img {
    width: 30px;
  }
  footer {
    font-size: 14px;
  }
  footer .centrado-2 {
    padding: 0 !important;
  }
  .mobile-py-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  #navbarResponsive {
    text-align: center !important;
  }
  #logo-mobile {
    text-align: center;
    position: absolute;
    top: 10px;
    width: 35%;
    margin-left: 30%;
    display: block;
  }
  #info-header-farmacia {
    font-size: 10px;
  }
  #info-header-farmacia img {
    display: block;
  }
  .header-farmacia h1 {
    font-size: 30px;
  }
  .header-farmacia {
    padding: 0;
  }
  .que-necesitas #precio {
    font-size: 18px;
  }
  .que-necesitas h3 {
    font-size: 8px;
  }
  #slider-home .carousel-indicators {
    bottom: 5px !important;
    position: absolute;
  }
  .carousel-indicators {
    bottom: 0px !important;
    position: relative;
  }
  .titular-farmacia {
    font-size: 1em;
  }
  .icons-que-necesitas button {
    font-size: 10px;
  }
  .que-necesitas h2 {
    font-size: 15px;
  }
  .detalle-producto #nombre {
    font-size: 12px;
  }
  .detalle-producto #precio {
    font-size: 25px !important;
  }
  .btn-add-to-car {
    padding: 5px 27px;
  }
  .text-info {
    font-size: 11px;
  }
  .revisar-pedido-mobile {
    padding: 0 !important;
    font-size: 9px;
  }
  .titles-compras-mob h3,
  .titles-compras-mob h4 {
    font-size: 10px;
  }
  .btn-carrito-mob {
    position: absolute;
    right: 15vw;
    top: 5px;
    background-color: transparent;
    border: none;
    display: inline;
  }
}

.isclosed {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  top: 5px;
  position: relative;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #46a19b;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.modal-content {
  padding: 30px;
}

.modal-content input {
  height: 25px;
  border-radius: 13px;
}

.detalle-producto #nombre {
  font-weight: bold;
}

.detalle-producto #precio {
  font-size: 42px;
}

.cantidades-control {
  border: solid 1px black;
  border-radius: 13px;
  width: 160px;
  display: inline;
}

.cantidades-control button {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 5px;
  margin: 5px 5px;
  width: 50px;
}

/* carrito */

.detalle-producto-sm #nombre {
  font-weight: bold;
  font-size: 10px;
}

.detalle-producto-sm #precio {
  font-size: 18px;
  font-weight: bold;
}

.cantidades-control-sm {
  border: solid 1px gray;
  border-radius: 13px;
  width: 140px;
  display: inline;
}

.cantidades-control-sm button {
  outline: none;
  background-color: transparent;
  padding: 2px;
  width: 40px;
  border: none;
}
.cantidades-control-sm button:hover {
  background-color: #d9e2e2;
}

.subtotal {
  font-size: 20px;
  color: #51a8c4;
}

#contenedorcompras {
  display: flex; /* or inline-flex */
  align-items: stretch;
}

#Numerogris {
  display: flex; /* or inline-flex */
  color: #b5bec0;
  font-size: 48pt;
  align-items: flex-start;
  font-weight: bold;
}

.hover-bold:hover {
  font-weight: bold !important;
}
.hover-bg-4f9cb5 :hover {
  background-color: #4f9cb5;
}
